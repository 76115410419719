import { FC } from 'react';

import { Match, SportDataTeam } from '@common/clients/api';
import { InternalLink } from '@web/atoms/InternalLink';
import { TagImage } from '@web/atoms/TagImage';
import { Route } from '@web/routing';

import styles from './ClubThumbnail.module.scss';

interface Props {
    classes?: string;
    club?: SportDataTeam;
    match?: Match;
}
export const ClubThumbnail: FC<Props> = ({ classes, club, match }) => (
    <div className={`${styles.ClubThumbnail} ${classes || ''}`}>
        <InternalLink
            route={club?.tag?.tagSlug ? Route.Club : Route.Match}
            query={club?.tag?.tagSlug ? { tag: club?.tag?.tagSlug } : { id: match?.id }}
        >
            <TagImage className={styles.logo} tag={club?.tag} />
        </InternalLink>
    </div>
);
