import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Link } from '@common/atoms/Link';
import { Match, ThreeWayOdds } from '@common/clients/api';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';
import { BettingDisclaimer, LogoVariant } from '@web/atoms/BettingDisclaimer';
import { BookmakerLogo } from '@web/atoms/BookmakerLogo';
import { Flexbox } from '@web/atoms/Flexbox';
import { Props as OddsDossierIntroProps } from '@web/molecules/OddsDossierIntro/OddsDossierIntro';

import { ClubThumbnail } from './ClubThumbnail';

import styles from './MatchBlock.module.scss';

interface Props extends OddsDossierIntroProps {
    hasDarkBackground?: boolean;
    match?: Match;
}

const isToday = (matchDate: Date): boolean => {
    const today = new Date();
    return (
        matchDate.getDate() === today.getDate() &&
        matchDate.getMonth() === today.getMonth() &&
        matchDate.getFullYear() === today.getFullYear()
    );
};

const keysInOdds = ['home', 'draw', 'away'] satisfies (keyof ThreeWayOdds)[];
const keyInOddsMap = {
    home: '1',
    draw: 'X',
    away: '2',
};

export const MatchBlock: FC<Props> = ({ hasDarkBackground = true, items, matches }) => {
    const __datetime = useTranslation('datetime').t;
    const match = matches[0];
    const bookmaker = items[0]?.bookmaker;
    const odds = match?.odds satisfies ThreeWayOdds[] | undefined;
    const threeWayOdds = odds?.[0] satisfies ThreeWayOdds | undefined;

    const url = match?.url || bookmaker?.url || '';
    const icon = hasDarkBackground ? bookmaker?.darkBackgroundIcon : bookmaker?.lightBackgroundIcon || '';

    const kickoff = new Date(match?.kickOff || new Date());
    const timeFormatted = DateTimeUtil.format(kickoff, Format.TIME_NUMERIC);
    const dateReadable = isToday(kickoff)
        ? __datetime('today')
        : DateTimeUtil.format(kickoff, Format.DATE_NUMERIC);

    const home = match?.home;
    const away = match?.away;

    return (
        <Flexbox column as="section" position="relative" classes={styles.MatchBlock}>
            <Flexbox row as="div" position="relative" align={'center'} classes={styles['club-row']}>
                <>
                    {/* <div className={styles['club-thumbnail']}>
                        <InternalLink
                            route={home?.tag?.tagSlug ? Route.Club : Route.Match}
                            query={home?.tag?.tagSlug ? { tag: home?.tag?.tagSlug } : { id: match?.id }}
                        >
                            <TagImage className={styles.logo} tag={home?.tag} />
                        </InternalLink>
                    </div> */}
                    <ClubThumbnail classes={styles['row-item']} club={home} match={match} />

                    <Flexbox column as={'div'} classes={styles['match-info']} position={'relative'}>
                        <h4 className={styles.time}>{timeFormatted}</h4>
                        <span className={styles.date}>{dateReadable}</span>
                    </Flexbox>
                    <ClubThumbnail classes={styles['row-item']} club={away} match={match} />
                </>
            </Flexbox>
            <Flexbox row as="div" position="relative" align={'center'} classes={styles['team-row']}>
                <h4 className={styles['shortname']}>{home?.shortName || home?.name || ''}</h4>
                <BookmakerLogo
                    alt={bookmaker?.name || ''}
                    url={url || bookmaker?.url || ''}
                    src={icon || ''}
                    className={`${styles['betting-logo']} ${styles['row-item']}`}
                />

                <h4 className={styles['shortname']}>{away?.shortName || away?.name || ''}</h4>
            </Flexbox>

            <Flexbox row as="ul" position="relative" align={'center'} classes={styles['odds-row']}>
                {threeWayOdds && match ? (
                    <>
                        {keysInOdds.map((keyInOdd, index) => (
                            <li key={`${keyInOdd}-${index}`}>
                                <Link href={threeWayOdds.url}>
                                    <a
                                        href={threeWayOdds.url}
                                        rel="sponsored nofollow"
                                        target="_blank"
                                        className={styles['betting-pill']}
                                    >
                                        {/* <div className={styles['betting-pill']}> */}
                                        <span className={styles['threeway-symbol']}>
                                            {keyInOddsMap[keyInOdd]}
                                        </span>
                                        <span className={styles.odd}>
                                            {Number(threeWayOdds[keyInOdd]).toFixed(2)}
                                        </span>
                                        {/* </div> */}
                                    </a>
                                </Link>
                            </li>
                        ))}
                    </>
                ) : null}
            </Flexbox>
            <Flexbox row as="div" position="relative" align={'center'} classes={styles['disclaimer-row']}>
                <BettingDisclaimer logoVariant={LogoVariant.TRANSPARENT} />
            </Flexbox>
        </Flexbox>
    );
};
